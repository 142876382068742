import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import { Row, Col } from 'react-bootstrap'
// import { useMediaQuery } from 'react-responsive'

import Layout from '../components/layout.js'

import republiqmp4 from '../images/republiq.mp4'
import fotamp4 from '../images/fota.mp4'

///ADD ANIMATION when they load, so they go up and itm akes the website feel premium

//maybe consider adding a date to HoverGif for temporal context

// this videos hash table  is here, so that when referencing it in the videoOrImg prop,
// the string will be recognized as a variable name referencing republiqmp4 or
// fotamp4, instead of just being interpreted as a string.
var videos = {
  republiqmp4: republiqmp4,
  fotamp4: fotamp4,
}

class IndexPage extends Component {
  constructor(props) {
    super(props)

    let footerActive;
    if (this.props.location.state == null) {
      footerActive = true;
    } else {
      footerActive = this.props.location.state.footerActive;
    }

    this.state = {
      footerActive: footerActive,
      windowWidth: undefined
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleFooter = this.toggleFooter.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    this.setState({ windowWidth });
    if (windowWidth < 960) {
      this.setState({ footerActive: false });
    }
  }

  toggleFooter() {
    const currentState = this.state.footerActive;
    this.setState({ footerActive: !currentState})
  }

  render() {
    const data = this.props.data
    
    //checking window width for breakpoint adjustment
    const mediumScreen = this.state.windowWidth < 1166
    const smallScreen = this.state.windowWidth < 960

    // console.log(data.allContentfulProject.nodes)

    //deconstructing graphQl data query
    const { allContentfulProject: { nodes: projects }} = data

    //dividing projects so they can be displayed in rows, each row is its own subarray
    const rows = []
    const projAmount = Math.floor(projects.length)
    var i;
    for (i=0; i < projAmount ; i++) {
      //different # of projects per rows, depending on screen size

      if (mediumScreen) {
        //adding to 'rows' array only if there are still projects to add
        if (projects.slice(i*2, i*2+2)[0]) {
          const widthOfFirst = projects.slice(i*2, i*2+2)[0].orientation
          //give horizontal
          if (widthOfFirst === 'horizontal') {
            rows.push(projects.slice(i*2, i*2+1))
            //note for future albert, if projectPair[1] is not horizontal, you need to make it such that projectPair[1] is the first element of the next pair.
            //currently, this code will make projectPair[1] its own row, even if it's only vertical/square
            //does that make sense? you need to mess with "i".
            rows.push(projects.slice(i*2+1, i*2+2))
          } else {
            rows.push(projects.slice(i*2, i*2+2))
          }
        }
      } else {
        //Bigger screens: 3 projects per row
        //multiplying by three avoid projects repeating in each row
        rows.push(projects.slice(i*3, i*3+3))
      }
    }

    //displays a video or photo block
    function videoOrImg(props) {
      const title = props.title.toLowerCase()
      const videoTitle = `${title}mp4`
      if (props.animated) {
        return (
          <button className='pushable'>
              {/* <GatsbyImage
              image={getImage(props.mainPicture)}
              alt={props.title}
              loading="eager"
              className='front'
              placeholder="tracedSVG"
            /> */}
            <video preload="auto" autoPlay muted loop playsInline
              className='front'
              style={{width: '100%', height: '100%'}}
            >
              <source src={videos[videoTitle]} type="video/mp4" />
            </video>
            <img
              src={props.hoverTitleGif.file.url}
              className='hoverGif'
              alt={props.hoverTitleGif.title}
            />
            {/*<img
              src={constructGif}
              className='constructGif'
              alt={constructGif}
            />*/}
          </button>
        )
      } else {
        return (
          <button className='pushable'>
            <GatsbyImage
              image={getImage(props.mainPicture)}
              alt={props.title}
              loading="eager"
              className='front'
              placeholder="tracedSVG"
            />
            <img
              src={props.hoverTitleGif.file.url}
              className='hoverGif'
              alt={props.hoverTitleGif.title}
            />
          </button>
        )
      }
    }

    return (
      <Layout isThin={smallScreen} footerActive={this.state.footerActive} toggleFooter={this.toggleFooter.bind(this)} isProj={false}>
      {/* nested array.map, displaying rows first, then cols in rows*/}
        {rows.map((row, index) => {
          return (
            <Row
              className={smallScreen ? 'rowSmall' : 'rowBig'}
              key={`row${index}`}
            >
              {row.map(project => {
                const img = getImage(project.mainPicture)
                return (
                  <Col sm="auto" style={{flex: img.width/img.height}} key={project.title}>
                    <Link to={`/${project.title}`} state={{ footerActive: this.state.footerActive}}>
                      {videoOrImg(project)}
                    </Link>
                  </Col>
                )
              })}
            </Row>
          )
        })}
        {/*shitty code, just to control the spacing at the bottom on some
          screen sizes*/}
        {smallScreen ? <div/> : mediumScreen ? <div style={{height: 100}}/> : <div/> }
      </Layout>
    )
  }
}

export const query = graphql`
  query MyQuery {
    allContentfulProject(sort: {order: ASC, fields: order}){
      totalCount
      nodes {
        animated
        id
        contentful_id
        title
        hoverTitleGif {
          file {
            url
          }
        }
        mainPicture {
          gatsbyImageData (
            placeholder: TRACED_SVG
          )
        }
        orientation
      }
    }
  }
`


export default IndexPage
